<template>
  <footer class="footer">
    <p class="text1">Casa de Retiros Betania del Sagrado Corazón</p>
    <br><br>
    <p class="text"> CONTÁCTANOS</p>
    <br><br>
    <p class="text"> Whatsapp: 314 587 33 85</p>
    <br><br>
    <p class="text"> Instagram: betaniac.retiros</p>
    <br><br>
    <div class="icon-wrapper">
      <a href="https://api.whatsapp.com/send?phone=573145873385"><img src="@/assets/iconogr1.png" alt="icono1" class="icon"></a>
      <a href="https://www.instagram.com/betaniac.retiros"><img src="@/assets/iconogr2.png" alt="icono2" class="icon"></a>
    </div>
    <p class="text2"> "Y dejándolos, salió fuera de la ciudad, a Betania, y se hospedó allí"  Mt 21:17</p>
    <br><br>
  </footer>
</template>



<style>
.footer {
  bottom: 0;
  width: 100%;
  background-color: #2c4e67;
  color: white;
  text-align: center;
  padding: 20px 0; 
  margin-top: 0px;
}

.text {
  margin: 0;
}
.text1 {
  font-size: 20px;
}

.icon-wrapper {
  display: flex;
  justify-content: center;
}

.icon {
  margin: 10px;
  width: 30px;
  height: 30px;
}
</style>