<template>
  <div class="testimonial-container" v-if="isAOSInitialized">
    <h2 class="testimonial-title" data-aos="fade-up" data-aos-once="true">Testimonios</h2>
    <div class="testimonial-list">
      <div class="testimonial" v-for="(testimonial, index) in testimonials" :key="index" data-aos="fade-up" data-aos-delay="100 * index" data-aos-once="true">
        <div class="testimonial-content">
          <p class="testimonial-text">{{ testimonial.texto }}</p>
          <p class="testimonial-author">{{ testimonial.autor }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  data() {
    return {
      testimonials: [
        {
          texto: "Betania del Sagrado Corazón, para mi es como subir a la montaña y estar con Dios y hablar con El cara a cara.",
          autor: "Alba Mery",
        },
        {
          texto: "Betania es lugar que me conecta, por así decirlo, conmigo misma, con Dios y con los demás.",
          autor: "Alba Mery",
        },
        {
          texto: "La casa de Betania es un lugar propicio e ideal para tener un encuentro personal con Dios en medio del silencio y la Paz que allí se siente.",
          autor: "Gabriel y Silvia",
        },
        {
          texto: "Betania del Sagrado Corazón es un pedacito de cielo, nos ha hecho crecer espiritualmente, en la fe, en el amor y en la esperanza.  ",
          autor: "Gabriel y Silvia",
        },        
        {
          texto: "El silencio que se experimenta y la Vida de oración de las hermanas me ha ayudado a caminar con el Señor y de la virgen María. Todos deberíamos conocer este lugar! Es increíble!  ",
          autor: "Pablo Andrés Muñoz",
        },
        {
          texto: "Yo lo defino como lo más cercano al cielo que hay aquí en la tierra.",
          autor: "Pablo Andrés Muñoz",
        },
    
      ],
      isAOSInitialized: false
    };
  },
  mounted() {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true
    });
    this.isAOSInitialized = true;
  },
};
</script>

<style>
.testimonial-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
  margin-bottom: 80px;
}

.testimonial-title {
  font-size: 32px;
  font-weight: bold;
  color: #2c4e67; 
  text-align: center;
  margin-bottom: 30px;
}

.testimonial-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.testimonial {
  background-color: #fafad2; 
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.testimonial-content {
  margin-bottom: 20px;
}

.testimonial-text {
  font-size: 18px;
  color: #666; 
  margin-bottom: 10px;
  line-height: 1.5;
}

.testimonial-author {
  font-size: 16px;
  font-weight: bold;
  color: #2c4e67; 
}

@media screen and (max-width: 768px) {
  .testimonial-list {
    grid-template-columns: 1fr; /* Mostrar solo una columna en dispositivos móviles */
  }

  .testimonial {
    margin-bottom: 20px; /* Espaciado entre las fichas en dispositivos móviles */
  }
}
</style>