<template>
  <main>
    <section class="banner">
      <div class="banner-img-container">
        <img :src="require('@/assets/img5.jpg')" alt="Betania" />
      </div>
    </section> 
  </main>
</template>

<style scoped>
.banner {
  position: relative;
  height: 500px;
  background-color: #f5f5f5;
  text-align: center;
  display: block;
}

.banner .banner-img-container {
  height: 100%;
  overflow: hidden;
}

.banner img {
  height: 100%;
  width: 100%;
  max-height: 100%;
  object-fit: none;
  object-position: center;
}
</style>