<template>
  <div class="iconos-redes-sociales">
    <a href="https://api.whatsapp.com/send?phone=573145873385">
      <img src="@/assets/icono2.png" alt="Logo" style="width: 190px; height: 60px;">
    </a>    
  </div>
</template>

<script>
export default {
  name: 'IconosRedesSociales'
}
</script>

<style>
.iconos-redes-sociales {
  position: fixed;
  bottom: 40px;
  right: 20px;
  z-index: 9999;
}
</style>