<template>
  <div>
    <navbar></navbar> <iconos-redes-sociales></iconos-redes-sociales>
    
    <router-view></router-view> 
 <Footer></Footer>
  </div>
  
</template>

<script>
import Navbar from './components/Navbar.vue' 
import IconosRedesSociales from './components/IconosRedesSociales.vue'
import Footer from './components/Footer.vue'


export default {
  name: 'App',
  components: {
    Navbar, 
    IconosRedesSociales,
    Footer,
  }
}
</script>