<template>
  <div>
    <Banner2></Banner2>     
    <serviciosA></serviciosA>
    <Horarios></Horarios>
    <galeria></galeria>
  </div>
</template>

<script>
import Banner2 from '@/components/Banner2.vue';
import Galeria from '@/components/Galeria.vue';
import ServiciosA from '@/components/ServiciosA.vue';
import Horarios from '@/components/Horarios.vue';

export default {
  name: 'Home',
  components: {
    Banner2,
    Galeria,
    ServiciosA,
    Horarios
  }
}
</script>

<style>

.title {
  font-size: 48px; 
  font-weight: bold;
  text-align: center;
  margin-top: 80px; 
  margin-bottom: 80px; 
  color: #333; 
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1); 
}
</style>




