<template>
  <div class="container" v-if="isAOSInitialized" data-aos="fade-up" data-aos-once="true">
    <img src="@/assets/icono.png" class="logo" alt="Logo">
    <h1 class="title">Misión</h1>
    <p class="paragraph">
      “El apostolado y la misión de la Fraternidad Misioneras Eucarísticas del Espíritu Santo tiene su origen y su modelo en el Amor Trinitario” Const. Art. 89 
      <br><br>
      La acogida fraterna: el esfuerzo y dedicación por ofrecer un espacio propicio y dispuesto para el encuentro con Dios, en hacer de esta casa de retiro un centro de irradiación del Amor Infinito. 
      <br><br>
      Con sencillez y cordialidad se acoge en Betania a cuantos llegan para orar y renovarse en el Espíritu, brindando un ambiente sereno y recogido. salvaguardando las exigencias de la vida contemplativa. 
    </p>
  </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  data() {
    return {
      isAOSInitialized: false
    };
  },
  mounted() {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true
    });
    this.isAOSInitialized = true;
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-bottom: 0px;
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  background-color: #f0f5ff; 
  background-image: none;
}

.logo {
  opacity: 0.8;
  width: 90px;
  height: 100px;
  margin-top: 60px;
}

.title {
font-size: 36px !important;
  font-weight: bold;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 80px;
  color: #2c4e67;
  text-shadow: 2px 2px 2px rgba(76, 43, 184, 0.1);
}
.paragraph {
  font-size: 18px;
  line-height: 1.5;
  text-align: justify;
  margin-top: 20px;
  margin-bottom: 100px;
  max-width: 800px;
  color: #666;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
</style>