<template>
  <div class="container" v-if="isAOSInitialized" data-aos="fade-up" data-aos-once="true">
    <div class="logo-container">
      <img src="@/assets/icono.png" class="logo" alt="Logo">
    </div>
    <h1 class="title">¡Descubre quiénes somos!</h1>
    <p class="paragraph">
      Somos una comunidad religiosa femenina de vida semi contemplativa, llamada Fraternidad Misioneras Eucarísticas del Espíritu Santo que lleva el lema, “Adorar a Jesús Eucaristía y servirlo en los pobres”. Hemos “nacido del deseo de algunas hermanas de vivir en profunda comunión entre sí, ejercitando una fuerte experiencia del amor de Dios” Const. Art.1  
      <br><br>  
      Dentro de nuestra comunidad contamos con diversos apostolados entre los cuales tenemos el cuidado y protección de niños y niñas en situación de vulnerabilidad, la ayuda material y espiritual a los pobres. En esta obra Betania del Sagrado Corazón ofrecemos acogida y hospitalidad a quienes desean un espacio de silencio y oración, de encuentro fraterno, comunitario y familiar, así también de descanso, todo ello enmarcado en una dimensión espiritual y trascendente. 
      <br><br>
      Te invitamos a compartir esta experiencia de estar a los pies del Maestro en nuestra casa de retiros, y vivir de cerca aquello que experimentó Jesús en el encuentro fraterno y cercano en la casa de Betania, la casa de los amigos. 
    </p>
  </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  data() {
    return {
      isAOSInitialized: false
    };
  },
  mounted() {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true
    });
    this.isAOSInitialized = true;
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  background-color: #f0f5ff; 
  background-image: none;
}

.logo {
  opacity: 0.8;
  width: 90px;
  height: 100px;
  margin-top: 60px;
}

.title {
  font-size: 36px !important;
  font-weight: bold;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 40px;
  color: #2c4e67;
  text-shadow: 2px 2px 2px rgba(76, 43, 184, 0.1);
}

.paragraph {
  font-size: 18px;
  line-height: 1.5;
  text-align: justify;
  margin-top: 20px;
  margin-bottom: 100px;
  max-width: 800px;
  color: #666;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 480px) {
  .paragraph {
    font-size: 16px;
  }
}
</style>