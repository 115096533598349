<template>
  <div>
    <header>      
      <nav>
        <ul class="menu">
          <li class="logo"><img src="@/assets/logo.png" alt="Logo" style="width: 60px; height: 60px;"></li>
          <li>
            <router-link to="/" exact>Inicio</router-link>
          </li>
          <li>
            <router-link to="/about">Misión y Obra</router-link>
          </li>
          <li>
            <router-link to="/servicios">Servicios</router-link>
          </li>
          <li>
            <router-link to="/contacto">Contacto</router-link>
          </li>
        </ul>
      </nav>
    </header>    
  </div>
</template>

<style>
.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 1.5625%;
  font-size: 100%;
  list-style: none; 
}

.menu li {
  margin: 0 2.34375%;
}

.menu a {
  color: #747171;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
}

.menu a:hover,
.menu a.router-link-exact-active {
  color: #000000;
  font-size: 93.75%; 
  transition: font-size 0.3s ease; 
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .menu {
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
  }
  
  .menu li {
    margin: 1.5625% 1.5625%;
  }
  
  .logo {
    flex-basis: 100%;
    text-align: center;
  }
}
</style>
