<template>
  <div>
    <Banner1></Banner1>
    <Bienvenida></Bienvenida>
    <Novedades></Novedades>
    <Testimonios></Testimonios>
  </div>
</template>

<script>
import Banner1 from "@/components/Banner1.vue";
import Novedades from "@/components/Novedades.vue";
import Bienvenida from "@/components/Bienvenida.vue";
import Testimonios from "@/components/Testimonios.vue";

export default {
  components: {
    Banner1,
    Novedades,
    Bienvenida,
    Testimonios
  },
}
</script>



