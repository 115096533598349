<template>
  <div class="container" style="background-color: #f0f5ff;" data-aos="zoom-in">
    <h1 class="title" data-aos="zoom-in">Servicios</h1>
    <p class="paragraph" style="background: linear-gradient(45deg, #f5f5dc, #ffe4c4);" data-aos="zoom-in">
      
      En Betania del Sagrado Corazón, te ofrecemos un lugar apropiado donde podrás encontrar serenidad y silencio. Nuestro servicio de acogida fraterna pone a su disposición nuestras instalaciones, así como el servicio de alimentación  y alojamiento. 
      <br><br>     
      Nuestra planta física está diseñada para su bienestar. Disponemos de habitaciones cómodas y acogedoras, espacios para la meditación y Capilla, así como hermosos jardines y un paisaje extenso que refleja el amor creador de Dios. Aquí, podrás disfrutar de momentos de silencio, soledad y encuentro personal. Está pensado también para convivencias, retiros espirituales, actividades pastorales, encuentros comunitarios y familiares. Contamos también con una casa familiar, que dispone de todo lo necesario en un espacio independiente y completo. 
      <br><br>
      Tenemos la celebración de la Santa Eucaristía que sostiene nuestra vivencia espiritual, de lunes a sábado a las 6:30 am. 
    </p>  
  </div>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.logo-container {
  margin-top: 80px;
}

.logo {
  opacity: 0.8;
  width: 90px;
  height: 100px;
}

.title {
  font-size: 36px !important;
  font-weight: bold;
  text-align: center;
  margin-top: 60px;
  color: #2c4e67;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  animation: titleAnimation 2s ease-in-out infinite alternate;
}

@keyframes titleAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.paragraph {
  font-size: 18px;
  line-height: 1.5;
  text-align: justify;
  margin-top: 20px;
  max-width: 800px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 100px;
  padding: 20px;
  border-radius: 10px;
}

@media only screen and (max-width: 480px) {
  .paragraph {
    font-size: 16px;
  }
}
</style>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  mounted() {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
    });
  },
};
</script>