<template>
<div class="welcome-container">
  <h1 class="welcome-title">¡Bienvenidos a Betania!</h1>
  <div class="welcome-content">
    <p class="welcome-text" data-aos="fade-right" data-aos-once="true">
      Betania del Sagrado Corazón es una casa de retiros, para encontrarnos con el centro y fuente de la vida, Dios; donde encontrarás paz, descanso y un espacio propicio para orar y estar en diálogo de amigos con Jesús. Como casa de retiros católicos especializada en servicios de acogida, nos enorgullece brindarte un entorno acogedor y fraterno. 
    </p>
    <p class="welcome-text" data-aos="fade-left" data-aos-once="true">
      Explora nuestro sitio web para descubrir todas las posibilidades de alojamiento y las oportunidades que ofrecemos para tu crecimiento espiritual. Esperamos que encuentres en Betania un lugar de retiro, para estar a los pies del Maestro y dejarnos formar según su corazón Santísimo, encontrarte contigo mismo y experimentar la paz interior que anhelas. 
    </p>
  </div>
</div>
</template>

<style>
.welcome-container {
  max-width: 1500px;
  margin: 2px auto;
  padding: 2px;
  text-align: center;
  background-color: #f4f3fc;
  border-radius: 10px;
}

.welcome-title {
  font-size: 36px;
  font-weight: bold;
  color: #2c4e67;
  margin-bottom: 10px;
}

.welcome-content {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcome-text {
  font-size: 18px;
  color: #666666;
  margin-bottom: 10px;
  line-height: 1.5;
  text-align: center;
}
</style>

<script>

import 'aos/dist/aos.css';

export default {
  mounted() {
   
  },
};
</script>