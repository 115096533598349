<template>
  <div>
    <Banner2></Banner2>
    <QuienesSomos></QuienesSomos>
    <Mision></Mision>    
  </div>
</template>

<script>
import Banner2 from '@/components/Banner2.vue';
import Mision from '@/components/Mision.vue';
import QuienesSomos from '@/components/QuienesSomos.vue';

export default {
  name: 'Home',
  components: {
    Banner2,
    Mision,
    QuienesSomos,
  },
};
</script>
