<template>
  <div class="container">
    <div class="content">
      <h2 class="main-title">Novedades</h2>
      <div class="tiles">
        <div class="tile" v-for="(evento, index) in eventos" :key="index" @click="openModal(evento)">
          <div class="circle">
            <img :src="evento.imgPrevia" :alt="'Imagen ' + (index + 1)">
          </div>
          <div class="text">
            <h3 class="title" :class="{'red': evento.titulo === 'Semana Santa' || evento.titulo === 'Retiros 2022' || evento.titulo === 'Retiros 2023'}">{{ evento.titulo }}</h3>
            <p class="description">{{ evento.descripcion }}</p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="modalOpen" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <div class="modal-header">
          <h2 class="modal-title">{{ modalEvento.titulo }}</h2>
        </div>
        <div class="modal-body">
          <div v-for="(img, index) in modalImgs" :key="index" class="modal-img">
            <img :src="img" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      eventos: [
        {
          titulo: 'Retiros Sacerdotes',
          imgPrevia: require('@/assets/ima6.jpg'),
          imgCompleta: [
            require('@/assets/ima1.jpg'),
            require('@/assets/ima2.jpg'),
            require('@/assets/ima3.jpg'),
            require('@/assets/ima4.jpg'),
            require('@/assets/ima5.jpg'),
            require('@/assets/ima6.jpg'),
          ],
        },
        {
          titulo: 'Retiros Religiosas',
          imgPrevia: require('@/assets/ima13.jpg'),
          imgCompleta: [
            require('@/assets/ima8.jpg'),
            require('@/assets/ima9.jpg'),
            require('@/assets/ima10.jpg'),
            require('@/assets/ima11.jpg'),
             require('@/assets/ima12.jpg'),
            require('@/assets/ima13.jpg'),
          ],
        },
        {
          titulo: 'Retiros Amig@s Betania',
          imgPrevia: require('@/assets/ima17.jpg'),
          imgCompleta: [
            require('@/assets/ima15.jpg'),
            require('@/assets/ima16.jpg'),
            require('@/assets/ima17.jpg'),
          ],
        },
         {
          titulo: 'Solemnidad Sagrado Corazón',
          imgPrevia: require('@/assets/ima18.jpg'),
          imgCompleta: [
            require('@/assets/ima19.jpg'),
            require('@/assets/ima20.jpg'),
            require('@/assets/ima21.jpg'),
            require('@/assets/ima22.jpg'),
            require('@/assets/ima23.jpg'),
            require('@/assets/ima24.jpg'),
            require('@/assets/ima25.jpg'),
            require('@/assets/ima26.jpg'),
          ],
        },
      ],
      modalOpen: false,
      modalImgs: [],
      modalEvento: null,
    };
  },
  methods: {
    openModal(evento) {
      this.modalOpen = true;
      this.modalImgs = evento.imgCompleta;
      this.modalEvento = evento;
      document.body.style.overflow = 'hidden'; 
    },
    closeModal() {
      this.modalOpen = false;
      this.modalImgs = [];
      this.modalEvento = null;
      document.body.style.overflow = ''; 
    },
  },
};
</script>

<style scooped>
.container {
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 20px;
  background-color: #f6f6f6;
  margin-top: 0;
}

.content {
  width: 100%;
  max-width: 1500px;
  margin-top: 40px;
  padding: 0 20px;
}

.main-title {
  text-align: center;
  font-size: 40px;
  color: #2c4e67;
  margin-bottom: 40px;
}

.tiles {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.tile {
  width: 1000px;
  height: 200px;
  margin-bottom: 30px;
  background-color: #f0f0f0;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  transition: background-color 0.2s ease-in-out, transform 0.3s ease-in-out;
  cursor: pointer;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.tile:hover {
  background-color: #e0e0e0;
  transform: scale(1.02);
}

.tile:nth-child(even) {
  flex-direction: row-reverse;
}

.circle {
  width: 300px;
  height: 300px;
  background-color: #ccc;
  border-radius: 50%;
  margin: 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.circle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
}

.title {
  margin-top: 5px;
  margin-bottom: 3px;
  font-size: 34px !important; 
  color: #2c4e67 !important; 
}

.title.red {
  color: #3a7171;
  font-size: 300%;
}

.description {
  font-size: 24px;
  color: #666666;
  margin: 0;
  margin-top: -50px;
  margin-bottom: 40px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #f0f0f0;
  padding: 20px;
  width: 80%;
  max-width: 800px;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
  border-radius: 10px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
  padding: 10px;
  color: #333333;
}

.modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  text-align: center;
}
.modal-title {
  font-size: 28px;
  color: #2c4e67;
}

.modal-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.modal-img {
  margin: 10px;
  width: 200px;
  height: 200px;
  overflow: hidden;
}

.modal-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .tile {
    width: 90%;
    height: 60%;
  }

  .circle {
    width: 140px;
    height: 140px;
    margin: 0 10px;
  }

  .title.red {
    font-size: 180%;
  }

  .description {
    font-size: 14px;
  }
  
  .main-title {
    font-size: 32px !important;
    color: #2c4e67 !important;
  }
  
  .title {
    font-size: 16px !important;
    color: #2c4e67 !important;
  }
}
</style>