<template>
  <div>
    <Banner3></Banner3>
    <ContactoA></ContactoA>
  </div>
</template>

<script>
import Banner3 from '@/components/Banner3.vue';
import ContactoA from '@/components/ContactoA.vue';

export default {
  name: 'Home',
  components: {
    Banner3,
    ContactoA
  },
};
</script>
<style>
  * {
    font-family: 'Poppins', sans-serif;
  }
</style>