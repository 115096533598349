<template>
  <div class="gallery" @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd">
    <h1 class="title">Nuestros espacios</h1>
    <div class="slider" ref="slider" style="position: relative; box-shadow: 0px 0px 20px rgba(0,0,0,0.5); height: 80vh;" @click="handleSliderClick">
      <img :src="images[currentImageIndex]" alt="Gallery image" style="width: 100%; height: 100%; object-fit: cover;" />
      <div class="navigation" style="position: absolute; bottom: 20px; left: 50%; transform: translateX(-50%);">
        <span v-for="(image, index) in images" :key="index" :class="{ 'active': index === currentImageIndex }" @click="changeImage(index)"></span>
      </div>
    </div>    
    <div class="arrows">
      <span class="arrow arrow-left" @click="changeImage((currentImageIndex - 1 + images.length) % images.length)">
        &#10094;
      </span>
      <span class="arrow arrow-right" @click="changeImage((currentImageIndex + 1) % images.length)">
        &#10095;
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentImageIndex: 0,
      images: [
        require('@/assets/image8.jpg'),
        require('@/assets/image9.jpg'),
        require('@/assets/image10.jpg'),
        require('@/assets/image11.jpg'),
        require('@/assets/image12.jpg'),
      ],
      intervalId: null,
      touchStartX: 0,
      touchEndX: 0,
    };
  },
  mounted() {
    this.startAutoChangeImage();
  },
  beforeUnmount() {
    this.stopAutoChangeImage();
  },
  methods: {
    startAutoChangeImage() {
      this.intervalId = setInterval(this.autoChangeImage, 5000);
    },
    stopAutoChangeImage() {
      clearInterval(this.intervalId);
    },
    autoChangeImage() {
      this.nextImage();
    },
    changeImage(index) {
      if (index !== this.currentImageIndex) {
        this.currentImageIndex = index;
        this.stopAutoChangeImage();
        this.startAutoChangeImage();
      }
    },
    onTouchStart(event) {
      this.touchStartX = event.touches[0].clientX;
      this.stopAutoChangeImage();
    },
    onTouchMove(event) {
      this.touchEndX = event.touches[0].clientX;
    },
    onTouchEnd() {
      const deltaX = this.touchEndX - this.touchStartX;
      if (deltaX > 50) {
        this.prevImage();
      } else if (deltaX < -50) {
        this.nextImage();
      }
      this.touchStartX = 0;
      this.touchEndX = 0;
    },
    handleSliderClick() {
      this.nextImage();
    },
    prevImage() {
      this.changeImage((this.currentImageIndex - 1 + this.images.length) % this.images.length);
    },
    nextImage() {
      this.changeImage((this.currentImageIndex + 1) % this.images.length);
    },
  },
};
</script>

<style scoped>
.gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  margin-bottom: 80px;
  background-color: #f2f2f2;
}

.slider {
  width: 100%;
  margin: 0 auto;
}

.slider img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.navigation {
  display: flex;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.navigation span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(245, 227, 227);
  margin: 0 5px;
  cursor: pointer;
}

.navigation span.active {
  background-color: rgb(18, 19, 92);
}

.title {
  font-size: 36px !important;
  color: #2c4e67;
  text-align: center;
  margin-top: 150px;
}
.gallery {
  position: relative;
}

.arrows {
  margin-top: 10%; 
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 1;
}

.arrow {
  font-size: 48px;
  color: white;
  cursor: pointer;
  user-select: none;
  padding: 10px;
  border-radius: 50%;
}

.arrow-left {
  margin-left: 20px;
}

.arrow-right {
  margin-right: 20px;
}

@media (max-width: 767px) {
  .arrows {
    display: none;
  }
}

@media (min-width: 768px) {
  .arrows {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>