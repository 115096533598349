<template>
  <div class="container" data-aos="zoom-in" style="background-color: #f8f8f8;">
    <h1 class="title" style="color: #2c4e67;">Horarios</h1>
    <p class="paragraph" style="color: #666;">
      De lunes a domingo de 7:00 am a 7:00 p.m.
    </p>
  </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  mounted() {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
    });
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 36px !important;
  font-weight: bold;
  text-align: center;
  margin-top: 60px;
  color: #2c4e67;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  animation: titleAnimation 2s ease-in-out infinite alternate;
}

@keyframes titleAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.paragraph {
  font-size: 24px;
  line-height: 1.5;
  text-align: justify;
  margin-top: 20px;
  margin-bottom: 80px;
  max-width: 800px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 480px) {
  .paragraph {
    font-size: 18px;
  }
}
</style>